import React, { useState, useContext, useEffect } from 'react';
import { StyleSheet, Text, View, Button, Image, ScrollView, TextInput, TouchableHighlight, ActivityIndicator } from 'react-native';
import QrReader from './QrReader';
import { AppLoading } from 'expo';
import { useFonts, OpenSans_400Regular, OpenSans_700Bold } from '@expo-google-fonts/open-sans';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useLinkTo } from '@react-navigation/native';
import AuthApi from "../api/authentication";
import Api from "../api/api";
import { UserContext } from '../components/UserContext';
import Cookie from 'js-cookie';
import { LoadingContext } from '../components/LoadingContext';

export default function Home(props) {
    const [loading, setLoading] = useState(false)
    const {value, setValue} = useContext(UserContext)
    const {globalLoading, setGlobalLoading} = useContext(LoadingContext)
    const [version, setVersion] = useState('')

    const dinamic_log = true;

  

    const linkTo = useLinkTo();
    let [fontsLoaded] = useFonts({
        OpenSans_400Regular,
        OpenSans_700Bold
    });

    useEffect(() => {
        const _version = localStorage.getItem('rotary_v')
        setVersion(_version)
    },[])


    async function login(values){
        setGlobalLoading(true)
        const resp = await AuthApi.login(values).catch(() => {})
        if(resp){
            Cookie.set('jwt', resp.data.data.token)
            Api.defaults.headers.common['Authorization'] = `Bearer ${resp.data.data.token}`;
            setValue({user:resp.data.data.user, loggedIn:true})
        }
        setGlobalLoading(false)
    }

    if (!fontsLoaded) {
        return <AppLoading />;
    }

    return (

        <React.Fragment>

        {loading && (
            <ActivityIndicator size="large" style={activity.container} />
        )}

        <ScrollView contentContainerStyle={{flexGrow: 1}}> 
            <View style={styles.container}>
                <Image style={{ width:300, height:150, maxWidth:'100%' }} resizeMode="contain" source={dinamic_log ? {uri:'https://api.westonrotaryrun.com/images/logo.png'}:require('../assets/logo-punteggi.jpg') }/>
                
                <Formik
                    initialValues={{ 
                        nickname:'', password:'' 
                    }}
                    onSubmit={(values, actions) => {
                        login(values)
                    }}
                    
                    validationSchema={validationSchema}
                    >
                    {({values, submitForm, setFieldValue, isValid, dirty, errors}) => (

                    <View style={{maxWidth:'80%', width:'100%', marginTop:100}}>
                        <View style={textStyles.container}>
                            <TextInput                                
                                style={textStyles.field}
                                placeholder={'User Name'}
                                onChangeText={text => setFieldValue('nickname', text)}
                                value={values.nickname}
                            />
                                <Text style={{ color: 'red', position:'absolute', fontWeight:'bold', bottom:-17, fontSize:12 }}>{errors.nickname}</Text>
                        </View>
                        
                        <View style={textStyles.container}>
                            <TextInput
                                style={textStyles.field}
                                placeholder={'Password'}
                                secureTextEntry={true}
                                onChangeText={text => setFieldValue('password', text)}
                                value={values.password}
                            />
                            <Text style={{ color: 'red', position:'absolute', fontWeight:'bold', bottom:-17, fontSize:12 }}>{errors.password}</Text>
                        </View>

                        <TouchableHighlight disabled={!(isValid && dirty)} onPress={() => submitForm()}>
                            <View style={[buttonStyle.container, {opacity:!(isValid && dirty) ? '0.5' : 1}]}>
                                <Text style={buttonStyle.text}>Sign in</Text>
                            </View>
                        </TouchableHighlight>

                        
                        <View style={passwordRecovery.container} >
                            <TouchableHighlight onPress={() => props.navigation.navigate('NicknameRecovery')}>
                                <Text style={passwordRecovery.text}>Nickname recovery</Text>
                            </TouchableHighlight>
                            <TouchableHighlight style={{marginTop:20}} onPress={() => props.navigation.navigate('PasswordRecovery')}>
                                <Text style={passwordRecovery.text}>Password recovery</Text>
                            </TouchableHighlight>
                        </View>

                        <TouchableHighlight onPress={() => linkTo('/sign-up')}>
                            <View style={buttonStyle.container}>
                                <Text style={buttonStyle.text}>Sign UP</Text>
                            </View>
                        </TouchableHighlight>
                    </View>
                )}
                </Formik>

            </View>
        </ScrollView>
        {version && version != '' && (
            <Text style={{padding:10, fontSize:11, fontWeight:'bold'}}>version:{version}</Text>
        )}
        </React.Fragment>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const activity = StyleSheet.create({
  container: {
    margin: 'auto',
    position: 'absolute',
    top: 0, left: 0, bottom: 0, right: 0,
    zIndex:9999
  },
});

const textStyles = StyleSheet.create({
  container: {
    marginBottom:30,
    position:'relative'
  },
  field: {
    height: 50, 
    borderColor: '#0f1c2d', 
    color:'#0f1c2d',
    borderWidth: 2, 
    width:'100%', 
    padding:5, 
    outlineColor: '#f7a81b',
    fontFamily: 'OpenSans_400Regular',
  }
});

const buttonStyle = StyleSheet.create({
    container:{
        width:'100%',
        height:50,
        backgroundColor:'#0f1c2d',
        justifyContent:'center',
        textAlign:'center',
        color:'white',
        alignContent:'center',
        fontFamily:'OpenSans_700Bold'
    },
    text: {
        color:'white',
        fontFamily:'OpenSans_700Bold',
        textTransform:'uppercase'
    }
})

const passwordRecovery = StyleSheet.create({
    container: {
        marginTop:30,
        marginBottom:30,
        width:'100%',
        justifyContent:'center',
        textAlign:'center',
        alignContent:'center',
    },
    text: {
        color:'#0f1c2d',
        fontFamily:'OpenSans_700Bold',
        textTransform:'uppercase'
    }
})

const validationSchema = Yup.object().shape({
    nickname: Yup.string()
      .label('Nickname')
      .required(),
    password: Yup.string()
      .label('Password')
      .required()
  })