import React, { useState, useContext, useEffect,useMemo } from 'react';
import { StyleSheet, Text, View, ScrollView, TextInput, TouchableHighlight,TouchableOpacity,Alert, Modal, Pressable } from 'react-native';
import { AppLoading } from 'expo';
import Header from '../components/Header';
import { useFonts, OpenSans_400Regular, OpenSans_700Bold, OpenSans_800ExtraBold } from '@expo-google-fonts/open-sans';
import { Formik,useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Picker, List, Checkbox, DatePicker } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import AuthApi from '../api/authentication';
import UserApi from '../api/user';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { LoadingContext } from '../components/LoadingContext';
import SearchGroup from './SearchGroup';
//import { set } from 'react-native-reanimated';



export default function SignUp(props) {
    const AgreeItem = Checkbox.AgreeItem;
    const {globalLoading, setGlobalLoading} = useContext(LoadingContext)
    const [searchGroup, setSearchGroup] = useState(false)
    const [visit, setVisit] = useState(false)
    const [groupSelected, setGroupSelected] = useState({

    })

    const [modalVisible, setModalVisible] = useState(false);
    const [modalTextEmail, setModalTextEmail] = useState('');


    const [objShape, setObjShape] = useState({
        parent_guardian_first_name: Yup.string()
        .label('Parent-guardian First Name')
        .required(),
        parent_guardian_last_name: Yup.string()
        .label('Parent-guardian Last Name')
        .required(),
        parent_guardian_phone: Yup.string()
        .label('Parent-guardian Phone')
        .required(),
        waiver: Yup.boolean()
        .label('Waiver')
        .required(),
        nickname: Yup.string()
          .label('Nickname')
          .required(),
        email: Yup.string()
          .label('Email')
          .email()
          .required(),
        password: Yup.string()
          .label('Password')
          .required()
          .min(8),
        password_confirmation: Yup.string()
          .label('Repeat Password')
          .required()
          .min(8)
          .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
        first_name: Yup.string()
          .label('First Name')
          .required(),
        last_name: Yup.string()
          .label('Last Name')
          .required(),
        school_id: Yup.string()
          .label('School Name')
          .required(),
        grade_id: Yup.string()
          .label('Grades')
          .required(),
    })
    const [addObjShape, setAddObjShape] = useState(
        Yup.object().shape(
        {
        nickname: Yup.string()
        .label('Nickname')
        .required(),
      email: Yup.string()
        .label('Email')
        .email()
        .required(),
      password: Yup.string()
        .label('Password')
        .required()
        .min(8),
      password_confirmation: Yup.string()
        .label('Repeat Password')
        .required()
        .min(8)
        .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
      first_name: Yup.string()
        .label('First Name')
        .required(),
      last_name: Yup.string()
        .label('Last Name')
        .required(),
      school_id: Yup.string()
        .label('School Name')
        .required(),
      grade_id: Yup.string()
        .label('Grades')
        .required(),
    })
    )



    const [validationSchema,setValidationSchema] = useState(
        Yup.object().shape(
            {
                ...objShape
            }
            )
    )


    const [grades, setGrades] = useState([])
    const [schools, setSchools] = useState([])
    const [itemGroupSelected, setItemGroupSelected] = useState(null)




    const [formickDataForm, setFormickDataForm] = useState({
        parent_guardian_first_name:'', 
        parent_guardian_last_name:'', 
        parent_guardian_phone:'',
        waiver:'',
        form:'',
    })
    const [formickDataAddChild, setFormickDataAddChild] = useState({
        child0:'',
        nickname:'',
        email:'',
        password:'', 
        password_confirmation:'', 
        first_name:'', 
        last_name:'', 
        school_id:'',
        grade_id:'',
        
    })

    const [childCount, setChildCount] = useState(0)

    let [fontsLoaded] = useFonts({
        OpenSans_400Regular,
        OpenSans_700Bold,
        OpenSans_800ExtraBold
    });

    const [pickerVisible, setPickerVisible] = useState(false)
    const [pickerGradesVisible, setPickerGradesVisible] = useState(false)
    const [datePickerVisible, setDatePickerVisible] = useState(false)

    async function get_grades(){
        const resp = await UserApi.get_grades().catch(() => {})
        if(resp){

            const _grades = resp.data.data.map(grade => {
                return {
                    label:grade.name,
                    value:grade.id
                }
            })

            setGrades(_grades)
        }
    }
    
    async function get_schools(){
        const resp = await UserApi.get_schools().catch(() => {})
        if(resp){

            const _schools = resp.data.data.map(school => {
                return {
                    label:school.name,
                    value:school.id
                }
            })

            setSchools(_schools)
        }
    }

    useEffect(() => {
        get_grades()
        get_schools()
    },[])

    const  isValidEmail = (email)=> {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
      }

    async function register(values, resetForm) {
      
      let arrayRegistration = []
      // dati genitori
      const   {parent_guardian_first_name, parent_guardian_last_name,parent_guardian_phone} = values;
      arrayRegistration[0] = []

      setGlobalLoading(true)
      // in values ci sono i dati dei figli adress primo figlio, adress1 secondo figliio ecc
      // itero values, pusho i dati dei figli all'interno di arrayRegistration in questo modo [figlio1, figlio2, figlio3, ecc]
      // ciclo arrayRegistration e per ogni figlio creo un oggetto con i dati del genitore e del figlio e lo invio al backend
      // in questo modo lascio inalterata la vecchia logica aggiungendo i nuovi campi

      let waiver_child = values.waiver ? true : false
      let checkProperty = true;
      Object.entries(values).forEach(([key, value]) => {
        // form è un campo aggiunto da me per la creazione dinamica del form
        if(key != 'form'){
          // le proprietà replicate sono del tipo child0, child1, child2, child3, child4, child5, child6, child7, child8, child9
          if(/\d/.test(key)){
            var numb = key.match(/\d/g);
            numb = numb.join("");
            let index = parseInt(numb,10)
            if(arrayRegistration[index]){
              if(key.indexOf('grade_id') > -1 || key.indexOf('school_id') > -1){
                if(key.indexOf('school_id') > -1){
                  let index_school_name = schools.findIndex(x => x.value == value);
                  arrayRegistration[index] = [...arrayRegistration[index],{[key]:value[0]},{'school_name':schools[index_school_name]['label']}]
                }else{
                arrayRegistration[index] = [...arrayRegistration[index],{[key]:value[0]}]
              }
              }else{
                arrayRegistration[index] = [...arrayRegistration[index],{[key]:value}]
              }
            }else{
              if(groupSelected[`group_id${index}`]){
                arrayRegistration[index] = []
                arrayRegistration[index] = [...arrayRegistration[index],{[key]:value},{group:groupSelected[`group_id${index}`]?.id ? groupSelected[`group_id${index}`]['id'] : ""},{waiver:waiver_child},{parent_guardian_first_name},{parent_guardian_last_name},{parent_guardian_phone}]
              }else {
                //checkProperty = false;
                arrayRegistration[index] = []
                arrayRegistration[index] = [...arrayRegistration[index],{[key]:value},{waiver:waiver_child},{parent_guardian_first_name},{parent_guardian_last_name},{parent_guardian_phone}]
              }
            }
          }else{
            if(arrayRegistration[0].length){
              if(key.indexOf('grade_id') > -1 || key.indexOf('school_id') > -1 ){
                if(key.indexOf('school_id') > -1){
                  let index_school_name = schools.findIndex(x => x.value == value);
                  arrayRegistration[0] = [...arrayRegistration[0],{[key]:value[0]},{'school_name':schools[index_school_name]['label']}]
                }else{
                  arrayRegistration[0] = [...arrayRegistration[0],{[key]:value[0]}]
                }
              }else{
                arrayRegistration[0] = [...arrayRegistration[0],{[key]:value}]
              }
            }else{
                arrayRegistration[0] = []
                arrayRegistration[0] = [...arrayRegistration[0],{[key]:value},{group:groupSelected['group_id']?.id ?groupSelected['group_id']['id'] :"" },{parent_guardian_first_name},{parent_guardian_last_name},{parent_guardian_phone}]
            }
          }
        }
      });


      // pulisco le proprietà e creo l'oggetto da passare al backend
      let arrayObj = []
      for (let i = 0;i < arrayRegistration.length; i++) {
        let obj1 ={}
        let result = arrayRegistration[i].reduce((obj, item) => {
          Object.keys(item).forEach(key => {
            // se nella chiave è presente un numero replico la chiave senza numero
            let key1 = key
            if(/\d/.test(key)){
              var numb = key.match(/\d/g);
              numb = numb.join("");
              key = key.replace(numb, '');
            }
            obj1[key] = item[key1];
            return obj;
          });
        }, {});
        arrayObj.push(obj1)
      }



      // i campi che si aspetta il backend
      const obj_value =[
        'nickname',
        'email',
        'password',
        'password_confirmation',
        'first_name', 
        'last_name', 
        'grade_id', 
        'school_id',
        //'group', 
        'parent_guardian_first_name',
        'parent_guardian_last_name', 
        'parent_guardian_phone',
        'waiver',
      ]
      obj_value.forEach(key => {
        for (let i = 0;i < arrayObj.length; i++) {
          if(!arrayObj[i][key]){
            console.log(key)
            checkProperty = false
            break;
          }
          // verifico che il campo mail sia valido con la regex
          if(key == 'email'){
            // verifico che il campo mail sia valido con la regex
            isValidEmail(arrayObj[i][key]) ? checkProperty = true : checkProperty = false
            if(!checkProperty){
              toastr.error('Please insert a valid email')
              setGlobalLoading(false)
              return
            }

            // verifico che il campo password e password_confirmation siano uguali

          }else if(key == 'password' || key == 'password_confirmation'){
            if(arrayObj[i][key] != arrayObj[i]['password_confirmation']){
              checkProperty = false
              toastr.error('Password and password confirmation must be equal')
              setGlobalLoading(false)
              return
            }
        }
        }
      });

      if(!checkProperty){
        toastr.error('Please fill in all the fields')
        setGlobalLoading(false)
        return
      }

      const promises_pre = arrayObj.map(async (obj) => {
        try {
          await AuthApi.pre_register(obj);
          return true; // La promessa è stata completata senza errori
        } catch (error) {
          // Gestisci l'errore qui se necessario
          toastr.error('Error during registration, please try again later');
          setGlobalLoading(false);
          return false; // La promessa ha generato un errore
        }
      });
      
      const preResults = await Promise.all(promises_pre);
      const checkCanSave = preResults.every((result) => result === true);
      
      function wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      
      async function registerSequentially(arrayObj) {
        for (const obj of arrayObj) {
          try {
            await AuthApi.register(obj);
            console.log('Richiesta inviata:', obj); // Puoi rimuovere questo console.log, è solo a scopo di debug
          } catch (error) {
            // Gestisci l'errore qui se necessario
            toastr.error('Error during registration, please try again later');
            setGlobalLoading(false);
            return false; // La promessa ha generato un errore
          }
          await wait(1500); // Attendere un secondo prima di inviare la prossima richiesta
        }
        return true; // Tutte le promesse sono state completate senza errori
      }
      
      // Utilizzo della funzione
      if (checkCanSave) {
        const allPromisesCompleted = await registerSequentially(arrayObj);
      
        if (allPromisesCompleted) {
          setGlobalLoading(false);
          resetForm();
          setModalVisible(true);
          //props.navigation.navigate('Home');
          //navigation.navigate('Home')
        }
      }
    
  }

    
    function closeSearchGroup(item){
        setSearchGroup(false)
        var body = document.getElementsByTagName("BODY")[0]
        body.classList.remove("fixed");
        if(item){
            let nameGroup = {[itemGroupSelected] : item}
            console.log(nameGroup)
            setGroupSelected({...groupSelected,...nameGroup})
        }
    }

    if (!fontsLoaded) {
        return <AppLoading />;
    }


    const addChild = (v,f) => {

      // v è l'oggetto con i dati dei campi del form, f è la lista dei campi che sono obligatori da compilare, se tutte le proprietà di f sono valorizzate in v allora aggiungo un nuovo figlio


      let newObj = {...v}
      let newForm = {...f}
      delete newObj.form
      Object.keys(newForm).forEach(key => {
        if(key.indexOf('form') > -1 || key.indexOf('child') > -1 || key.indexOf('group_id') > -1){
          delete newForm[key]
        }
      });
      let checkProperty = true;
      Object.keys(newForm).forEach(key => {
        if(!newObj[key]){
          if(key != 'group'){
            checkProperty = false
          }
        }
      });
      if(checkProperty){
       let updatedFormickDataForm = { ...formickDataForm };
        let newChild = { ...formickDataAddChild };
        // controllo quante propietà ha l'oggetto
        let newObjShape = {...addObjShape} 
        let count = Object.keys(updatedFormickDataForm).length;
        if (count < 6) {
            //setFormickDataForm(prevState => ({ ...prevState, ...newChild }));
        }else {
            // itero l'oggetto formickDataAddChild e aggiungo la variabile childcount alle proprieta
            Object.keys(newChild).map((item, index) => {
                newChild[item + childCount] = '';
            });
           // setFormickDataForm(prevState => ({ ...prevState, ...newChild }));

            // itero l'oggetto objShape e aggiungo la variabile childcount alle proprieta
            Object.keys(newObjShape).map((item, index) => {
                newObjShape[item + childCount] = '';
            }
            );
        }
        setFormickDataForm(prevState => ({ ...prevState, ...newChild }));
        setObjShape(prevState => ({ ...prevState, ...newObjShape }));
        setChildCount(childCount + 1);
      }else{
        toastr.error('Please fill in all the fields')
      }
    };

    const removeChild = () => {
        let updatedFormickDataForm = { ...formickDataForm };
        console.log("updatedFormickDataForm")
        console.log(updatedFormickDataForm)
        // rimuovo tutte le proprietà che hanno all'interno il numero childCount
        Object.keys(updatedFormickDataForm).forEach(key => {
            if(key.indexOf(childCount -1) > -1){
                delete updatedFormickDataForm[key]
            }
        }
        );

        setFormickDataForm(updatedFormickDataForm);
        setChildCount(childCount -1);



    };




    const itemGroup =  (item) => {
        setItemGroupSelected(item)
        setSearchGroup(true)
    }



    const goHome = () => {
        setModalVisible(false);
        props.navigation.navigate('Home')
    }
    let debounceTimeout;
    const existUser = () => {
      
      clearTimeout(debounceTimeout); // Cancella il timeout precedente
      debounceTimeout = setTimeout(() => {
        const email = document.querySelector('input[placeholder="Email"]').value;
        callApiToCheckEmail(email);
      }, 1000);
    };


    const callApiToCheckEmail = async (email) => {
      const resp = await AuthApi.exist_user({email:email}).catch(() => {})
      if(resp){
        if(resp.data.data){
          let message = "the email address is already in the system for the following nick name :\n";
          message += resp.data.data.join(', ')
          message += "\n if you want to add an additional child please proceed with the registration"
          setModalTextEmail(message)
          setModalVisible(true)
        }
      }
    }


    const closeModalAndClear = () => {
      setModalVisible(false)
      setModalTextEmail('')
    }

    return (

        <React.Fragment>
            {searchGroup && (
                <SearchGroup closeSearchGroup={closeSearchGroup} />
            )}
            <Header {...props} title="Sign Up" />
            {modalVisible && modalTextEmail ?
            <View style={styles_modal_exist_email.centeredView}>
                  <View style={styles_modal_exist_email.centeredView}>
                    <View style={styles_modal_exist_email.modalView}>
                      <Text style={styles_modal_exist_email.modalText}>
                        {modalTextEmail}
                      </Text>
                      <Pressable
                        style={[styles_modal.button, styles_modal_exist_email.buttonClose]}
                        onPress={() =>closeModalAndClear()}>
                        <Text style={styles_modal_exist_email.textStyle}>Close</Text>
                    </Pressable>
                    </View>
                  </View>
             </View> : <></>
            }
            { modalVisible  && !modalTextEmail? 
            <View style={styles_modal.centeredView}>
                  <View style={styles_modal.centeredView}>
                    <View style={styles_modal.modalView}>
                      <Text style={styles_modal.modalText}>
                        Registration completed. Please use your child nick name and Pw to log in!
                      </Text>
                      <Pressable
                        style={[styles_modal.button, styles_modal.buttonClose]}
                        onPress={() => goHome()}>
                        <Text style={styles_modal.textStyle}>Close</Text>
                    </Pressable>
                    </View>
                  </View>
             </View> : 
            <ScrollView contentContainerStyle={{flexGrow: 1, backgroundColor:'white'}}> 

                <View style={[styles.container, {marginTop:100, marginBottom:200}]}>
                    <Text style={{fontFamily:'OpenSans_800ExtraBold', color:'#0f1c2d', fontSize:40}}>Sign Up</Text>
                    <Formik
                        initialValues={{ 
                            ...formickDataForm,
                        }}
                        onSubmit={(values, actions) => {
                            register(values, actions.resetForm)
                        }}

                        validationSchema={validationSchema}
                        >
                        {({values, submitForm, setFieldValue, isValid, dirty, errors}) => (

                        <View style={{maxWidth:'80%', width:'100%', marginTop:100}}>
                            { Object.keys(formickDataForm).map((item, index) => {
                                if(item.indexOf("school_id") > -1) {
                                    return(
                                    <View style={textStyles.container} key={item}>
                                        <Picker
                                            data={schools}
                                            visible={pickerVisible}
                                            title="School Name"
                                            okText="Select"
                                            dismissText="Dismiss"
                                            extra=" "
                                            cols="1"
                                            value={values[item]}
                                            onOk={text => {setFieldValue(item, text); setPickerVisible(false)}}
                                            onDismiss={() => setPickerVisible(false)}
                                            >
                                            <List.Item arrow="horizontal" onClick={() => setPickerVisible(true)}>School Name</List.Item>
                                        </Picker>
                                        <Text style={{ color: 'red', position:'absolute', fontWeight:'bold', bottom:-17, fontSize:12 }}>{errors[item]}</Text>
                                    </View>
                                    )
                                }
                                else if (item.indexOf("grade_id") > -1) {
                                    return(
                                        <View style={textStyles.container} key={item}>
                                        <Picker
                                            data={grades}
                                            visible={pickerGradesVisible}
                                            title="Grades"
                                            okText="Select"
                                            dismissText="Dismiss"
                                            extra=" "
                                            cols="1"
                                            value={values[item]}
                                            onOk={text => {setFieldValue(item, text); setPickerGradesVisible(false)}}
                                            onDismiss={() => setPickerGradesVisible(false)}
                                            >
                                            <List.Item arrow="horizontal" onClick={() => setPickerGradesVisible(true)}>Grades</List.Item>
                                        </Picker>
                                        <Text style={{ color: 'red', position:'absolute', fontWeight:'bold', bottom:-17, fontSize:12 }}>{errors.item}</Text>
                                    </View>
                                    )
                                }else if (item =='waiver'){
                                    return (
                                        <View style={textStyles.container} key={item}>
                                        <AgreeItem disabled={!visit} data-seed="waiver" onChange={e => {
                                            e.preventDefault()
                                            setFieldValue('waiver', e.target.checked);
                                            }}>
                                             <a style={{textDecoration:'underline'}} onClick={(e) => { e.preventDefault(); setVisit(true); window.open('https://api.westonrotaryrun.com/waiver.pdf', '_blank');}}>
                                              You must click here to open the waiver. 
                                              The participant guardians and/or I agree, to the terms of service
                                            </a>
                                        </AgreeItem>
                                        <Text style={{ color: 'red', position:'absolute', fontWeight:'bold', bottom:-17, fontSize:12 }}>{errors.waiver}</Text>
                                    </View>
                                    )
                                }else if (item.indexOf('group_id') > -1){

                                    return(
                                        <View style={textStyles.container} key={index} >
                                        <TouchableHighlight onPress={() => {itemGroup(item)}}>
                                            <View style={[textStyles.field, {justifyContent:'center'}]}>
                                                <Text style={{color:'#7d7d7d'}}>{groupSelected[item] ? groupSelected[item].name : 'Select Group'}</Text>
                                            </View>
                                        </TouchableHighlight>
                                    </View>
                                    )
                                }else if (item.indexOf('child') > -1){
                                    let n_child = parseInt(item.match(/\d+/)[0]) +1;
                                    return(
                                        <View style={textStyles.container} key={index}>
                                                <Text style={{ color: 'red', position:'absolute', fontWeight:'bold', bottom:-17, fontSize:12 }}>Child {n_child}</Text>
                                                {childCount == n_child ? < TouchableOpacity onPress={() =>removeChild(values,formickDataForm)} style={buttonStyle.add}>
                                                  <Text style={buttonStyle.text}>X</Text>
                                                  </TouchableOpacity>:<></>}
                                        </View>
                                    )
                                }else if (item.indexOf('form') > -1){
                                    return (
                                        <View>
                                        </View>
                                      
                                    )
                                }else {
                                  let place_text = item.split('_')
                                  place_text = place_text.map((item) => {
                                      return item.charAt(0).toUpperCase() + item.slice(1)
                                  })
                                  let isPassword = item.indexOf('password') > -1 ? 'Your password must be at least 8 characters' : false
                                  place_text = isPassword ? place_text.join(' ') + '. ' + isPassword : place_text.join(' ')
                                  
                                  place_text = place_text == "Nickname" ? "User Name" : place_text
                                    return(
                                        <View style={textStyles.container} key={index}>
                                            <TextInput                                
                                                style={textStyles.field}
                                                placeholder={place_text}
                                                onChangeText={text => setFieldValue(item, text)}
                                                value={values[item]}
                                                onKeyPress={text => {
                                                  place_text === "Email" ? existUser() : null
                                                }}
                                              
                                            />
                                                <Text style={{ color: 'red', position:'absolute', fontWeight:'bold', bottom:-17, fontSize:12 }}>{errors.item}</Text>
                                        </View>
                                    )
                                }
                            })}

                            < TouchableOpacity onPress={() =>addChild(values,formickDataForm)} style={buttonStyle.add}>
                                    <Text style={buttonStyle.text}>Add Child</Text>
                            </TouchableOpacity>

                            <TouchableHighlight disabled={!(isValid && dirty)} onPress={() => submitForm()}>
                                <View style={[buttonStyle.container, {opacity:!(isValid && dirty) ? '0.5' : 1}]}>
                                    <Text style={buttonStyle.text}>Sign UP</Text>
                                </View>
                            </TouchableHighlight>
                        </View>
                    )}
                    </Formik>

                </View>
            </ScrollView>}
        </React.Fragment>
    );
}


const styles_modal_exist_email = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex:999999999999999999,
    position:'relative',
    top:'40vh',

  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '400px', // Ancho del modal
    //marginLeft: '4vw',
    borderColor: 'white !important',
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    fontSize:30,
    textAlign: 'center',
  },
});

const styles_modal = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '800px', // Ancho del modal
    //marginLeft: '4vw',
    borderColor: 'white !important',
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    fontSize:30,
    textAlign: 'center',
  },
});


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },

});

const activity = StyleSheet.create({
    container: {
      margin: 'auto',
      position: 'absolute',
      top: 0, left: 0, bottom: 0, right: 0,
      zIndex:9999
    },
  });

const textStyles = StyleSheet.create({
  container: {
    marginBottom:30,
    position:'relative'
  },
  field: {
    height: 50, 
    borderColor: '#0f1c2d', 
    color:'#0f1c2d',
    borderWidth: 2, 
    width:'100%', 
    padding:5, 
    outlineColor: '#f7a81b',
    fontFamily: 'OpenSans_400Regular',
  }
});

const buttonStyle = StyleSheet.create({
    container:{
        width:'100%',
        height:50,
        backgroundColor:'#0f1c2d',
        justifyContent:'center',
        textAlign:'center',
        color:'white',
        alignContent:'center',
        fontFamily:'OpenSans_700Bold'
    },
    add : {
      width:'100%',
      height:50,
      backgroundColor:'#0f1c2d',
      justifyContent:'center',
      textAlign:'center',
      color:'white',
      alignContent:'center',
      fontFamily:'OpenSans_700Bold',
      marginBottom:30,
    },
    text: {
        color:'white',
        fontFamily:'OpenSans_700Bold',
        textTransform:'uppercase'
    },
})

const passwordRecovery = StyleSheet.create({
    container: {
        marginTop:30,
        marginBottom:30,
        width:'100%',
        justifyContent:'center',
        textAlign:'center',
        alignContent:'center',
    },
    text: {
        color:'#0f1c2d',
        fontFamily:'OpenSans_700Bold',
        textTransform:'uppercase'
    }
})

/*const validationSchema = Yup.object().shape({
    parent_guardian_first_name: Yup.string()
    .label('Parent-guardian First Name')
    .required(),
    parent_guardian_last_name: Yup.string()
    .label('Parent-guardian Last Name')
    .required(),
    parent_guardian_phone: Yup.string()
    .label('Parent-guardian Phone')
    .required(),
    waiver: Yup.boolean()
    .label('Waiver')
    .required(),
    nickname: Yup.string()
      .label('Nickname')
      .required(),
    email: Yup.string()
      .label('Email')
      .email()
      .required(),
    password: Yup.string()
      .label('Password')
      .required()
      .min(8),
    password_confirmation: Yup.string()
      .label('Repeat Password')
      .required()
      .min(8)
      .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
    first_name: Yup.string()
      .label('First Name')
      .required(),
    last_name: Yup.string()
      .label('Last Name')
      .required(),
    school_id: Yup.string()
      .label('School Name')
      .required(),
    grade_id: Yup.string()
      .label('Grades')
      .required(),
    city: Yup.string()
      .label('City')
      .required(),
    address: Yup.string()
      .label('Address')
      .required(),
      
    nickname1: Yup.string()
      .label('Nickname')
      .required(),
    email1: Yup.string()
      .label('Email')
      .email()
      .required(),
    password1: Yup.string()
      .label('Password')
      .required()
      .min(8),
    password_confirmation1: Yup.string()
      .label('Repeat Password')
      .required()
      .min(8)
      .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
    first_name1: Yup.string()
      .label('First Name')
      .required(),
    last_name1: Yup.string()
      .label('Last Name')
      .required(),
    school_id1: Yup.string()
      .label('School Name')
      .required(),
    grade_id1: Yup.string()
      .label('Grades')
      .required(),
    city1: Yup.string()
      .label('City')
      .required(),
    address1: Yup.string()
      .label('Address')
      .required(),

      

      
     nickname2: Yup.string()
      .label('Nickname')
      .required(),
    email2: Yup.string()
      .label('Email')
      .email()
      .required(),
    password2: Yup.string()
      .label('Password')
      .required()
      .min(8),
    password_confirmation2: Yup.string()
      .label('Repeat Password')
      .required()
      .min(8)
      .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
    first_name2: Yup.string()
      .label('First Name')
      .required(),
    last_name2: Yup.string()
      .label('Last Name')
      .required(),
    school_id2: Yup.string()
      .label('School Name')
      .required(),
      grade_id2: Yup.string()
      .label('Grades')
      .required(),
    city2: Yup.string()
      .label('City')
      .required(),
    address2: Yup.string()
      .label('Address')
      .required(),
      
     nickname4: Yup.string()
      .label('Nickname')
      .required(),
    email4: Yup.string()
      .label('Email')
      .email()
      .required(),
    password4: Yup.string()
      .label('Password')
      .required()
      .min(8),
    password_confirmation4: Yup.string()
      .label('Repeat Password')
      .required()
      .min(8)
      .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
    first_name4: Yup.string()
      .label('First Name')
      .required(),
    last_name4: Yup.string()
      .label('Last Name')
      .required(),
    school_id4: Yup.string()
      .label('School Name')
      .required(),
      grade_id4: Yup.string()
      .label('Grades')
      .required(),
    city4: Yup.string()
      .label('City')
      .required(),
    address4: Yup.string()
      .label('Address')
      .required(),
     nickname4: Yup.string()
      .label('Nickname')
      .required(),
    email4: Yup.string()
      .label('Email')
      .email()
      .required(),
    password4: Yup.string()
      .label('Password')
      .required()
      .min(8),
    password_confirmation4: Yup.string()
      .label('Repeat Password')
      .required()
      .min(8)
      .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
    first_name4: Yup.string()
      .label('First Name')
      .required(),
    last_name4: Yup.string()
      .label('Last Name')
      .required(),
    school_id4: Yup.string()
      .label('School Name')
      .required(),
      grade_id4: Yup.string()
      .label('Grades')
      .required(),
    city4: Yup.string()
      .label('City')
      .required(),
    address4: Yup.string()
      .label('Address')
      .required(),
      
     nickname5: Yup.string()
      .label('Nickname')
      .required(),
    email5: Yup.string()
      .label('Email')
      .email()
      .required(),
    password5: Yup.string()
      .label('Password')
      .required()
      .min(8),
    password_confirmation5: Yup.string()
      .label('Repeat Password')
      .required()
      .min(8)
      .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
    first_name5: Yup.string()
      .label('First Name')
      .required(),
    last_name5: Yup.string()
      .label('Last Name')
      .required(),
    school_id5: Yup.string()
      .label('School Name')
      .required(),
      grade_id5: Yup.string()
      .label('Grades')
      .required(),
    city5: Yup.string()
      .label('City')
      .required(),
    address5: Yup.string()
      .label('Address')
      .required(),
     nickname5: Yup.string()
      .label('Nickname')
      .required(),
    email5: Yup.string()
      .label('Email')
      .email()
      .required(),
    password5: Yup.string()
      .label('Password')
      .required()
      .min(8),
    password_confirmation5: Yup.string()
      .label('Repeat Password')
      .required()
      .min(8)
      .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
    first_name5: Yup.string()
      .label('First Name')
      .required(),
    last_name5: Yup.string()
      .label('Last Name')
      .required(),
    school_id5: Yup.string()
      .label('School Name')
      .required(),
      grade_id5: Yup.string()
      .label('Grades')
      .required(),
    city5: Yup.string()
      .label('City')
      .required(),
    address5: Yup.string()
      .label('Address')
      .required(),
      
     nickname6: Yup.string()
      .label('Nickname')
      .required(),
    email6: Yup.string()
      .label('Email')
      .email()
      .required(),
    password6: Yup.string()
      .label('Password')
      .required()
      .min(8),
    password_confirmation6: Yup.string()
      .label('Repeat Password')
      .required()
      .min(8)
      .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
    first_name6: Yup.string()
      .label('First Name')
      .required(),
    last_name6: Yup.string()
      .label('Last Name')
      .required(),
    school_id6: Yup.string()
      .label('School Name')
      .required(),
      grade_id6: Yup.string()
      .label('Grades')
      .required(),
    city6: Yup.string()
      .label('City')
      .required(),
    address6: Yup.string()
      .label('Address')
      .required(),
     nickname6: Yup.string()
      .label('Nickname')
      .required(),
    email6: Yup.string()
      .label('Email')
      .email()
      .required(),
    password6: Yup.string()
      .label('Password')
      .required()
      .min(8),
    password_confirmation6: Yup.string()
      .label('Repeat Password')
      .required()
      .min(8)
      .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
    first_name6: Yup.string()
      .label('First Name')
      .required(),
    last_name6: Yup.string()
      .label('Last Name')
      .required(),
    school_id6: Yup.string()
      .label('School Name')
      .required(),
      grade_id6: Yup.string()
      .label('Grades')
      .required(),
    city6: Yup.string()
      .label('City')
      .required(),
    address6: Yup.string()
      .label('Address')
      .required(),
  })*/